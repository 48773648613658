var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-card',{attrs:{"body-style":{ padding: '24px 32px' },"bordered":false}},[_c('a-form',{attrs:{"form":_vm.form,"labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol},on:{"submit":_vm.handleSubmit}},[_c('a-tabs',{attrs:{"default-active-key":"1"}},[_c('a-tab-pane',{key:"1"},[_c('span',{attrs:{"slot":"tab"},slot:"tab"},[_c('a-icon',{attrs:{"type":"global"}}),_vm._v(" 常规 ")],1),_c('a-form-item',{attrs:{"label":"公司名"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'company_name',
            {
              initialValue: _vm.webConfig.company_name
            },
          ]),expression:"[\n            'company_name',\n            {\n              initialValue: webConfig.company_name\n            },\n          ]"}],attrs:{"placeholder":"公司名"}})],1),_c('a-form-item',{attrs:{"label":"电子邮箱地址"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'company_email',
            {
              initialValue: _vm.webConfig.company_email
            },
          ]),expression:"[\n            'company_email',\n            {\n              initialValue: webConfig.company_email\n            },\n          ]"}],attrs:{"placeholder":"邮箱地址"}})],1),_c('a-form-item',{attrs:{"label":"支持https"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'support_https',
            {
              initialValue: _vm.webConfig.support_https
            }
          ]),expression:"[\n            'support_https',\n            {\n              initialValue: webConfig.support_https\n            }\n          ]"}]},[_c('a-select-option',{attrs:{"value":"1"}},[_vm._v(" 是 ")]),_c('a-select-option',{attrs:{"value":"0"}},[_vm._v(" 否 ")])],1)],1),_c('a-form-item',{attrs:{"label":"域名"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'domain',
            {
              initialValue: _vm.webConfig.domain
            }
          ]),expression:"[\n            'domain',\n            {\n              initialValue: webConfig.domain\n            }\n          ]"}],attrs:{"placeholder":"域名"}})],1),_c('a-form-item',{attrs:{"label":"后台域名"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'admin_domain',
            {
              initialValue: _vm.webConfig.admin_domain
            }
          ]),expression:"[\n            'admin_domain',\n            {\n              initialValue: webConfig.admin_domain\n            }\n          ]"}],attrs:{"placeholder":"后台域名"}})],1),_c('a-form-item',{attrs:{"label":"logo地址"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'logo_url',
            {
              initialValue: _vm.webConfig.logo_url
            }
          ]),expression:"[\n            'logo_url',\n            {\n              initialValue: webConfig.logo_url\n            }\n          ]"}],attrs:{"placeholder":"输入您的徽标URL以显示在电子邮件中，或留空为空白"}})],1),_c('a-form-item',{attrs:{"label":"限制活动日志"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'active_log_count',
            {
              initialValue: _vm.webConfig.active_log_count
            }
          ]),expression:"[\n            'active_log_count',\n            {\n              initialValue: webConfig.active_log_count\n            }\n          ]"}],attrs:{"placeholder":"您希望保留的最大系统级别活动日志条目数"}})],1),_c('a-form-item',{attrs:{"label":"每页显示的记录"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'page_count',
            {
              initialValue: _vm.webConfig.page_count
            }
          ]),expression:"[\n            'page_count',\n            {\n              initialValue: webConfig.page_count\n            }\n          ]"}]},[_c('a-select-option',{attrs:{"value":"10"}},[_vm._v(" 10 ")]),_c('a-select-option',{attrs:{"value":"20"}},[_vm._v(" 20 ")]),_c('a-select-option',{attrs:{"value":"50"}},[_vm._v(" 50 ")])],1)],1),_c('a-form-item',{attrs:{"label":"维护模式"}},[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'mainte_status',
            {
              valuePropName: 'checked',
              initialValue: _vm.webConfig.mainte_status ? true : false
            },
          ]),expression:"[\n            'mainte_status',\n            {\n              valuePropName: 'checked',\n              initialValue: webConfig.mainte_status ? true : false\n            },\n          ]"}]},[_vm._v(" 勾选以启用-启用时禁止访问客户区域 ")])],1),_c('a-form-item',{attrs:{"label":"维护模式信息"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'mainte_desc',
            {
              initialValue: _vm.webConfig.mainte_desc
            }
          ]),expression:"[\n            'mainte_desc',\n            {\n              initialValue: webConfig.mainte_desc\n            }\n          ]"}],attrs:{"rows":"2","placeholder":"我们目前正在进行维护，早八点后可访问。"}})],1),_c('a-form-item',{attrs:{"label":"维护模式重定向URL"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'mainte_url',
            {
              initialValue: _vm.webConfig.mainte_url
            }
          ]),expression:"[\n            'mainte_url',\n            {\n              initialValue: webConfig.mainte_url\n            }\n          ]"}]})],1)],1),_c('a-tab-pane',{key:"2"},[_c('span',{attrs:{"slot":"tab"},slot:"tab"},[_c('a-icon',{attrs:{"type":"mail"}}),_vm._v(" 邮件 ")],1),_c('a-form-item',{attrs:{"label":"邮件类型"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'email_type',
            {
              initialValue: _vm.webConfig.email_type
            }
          ]),expression:"[\n            'email_type',\n            {\n              initialValue: webConfig.email_type\n            }\n          ]"}]},[_c('a-select-option',{attrs:{"value":"smtp"}},[_vm._v(" SMTP ")])],1)],1),_c('a-form-item',{attrs:{"label":"邮件编码"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'email_encoding',
            {
              initialValue: _vm.webConfig.email_encoding
            }
          ]),expression:"[\n            'email_encoding',\n            {\n              initialValue: webConfig.email_encoding\n            }\n          ]"}]},[_c('a-select-option',{attrs:{"value":"7bit"}},[_vm._v(" 7bit ")]),_c('a-select-option',{attrs:{"value":"8bit"}},[_vm._v(" 8bit ")]),_c('a-select-option',{attrs:{"value":"base64"}},[_vm._v(" base64 ")]),_c('a-select-option',{attrs:{"value":"binary"}},[_vm._v(" binary ")])],1)],1),_c('a-form-item',{attrs:{"label":"SMTP 主机"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'smtp_host',
            {
              initialValue: _vm.webConfig.smtp_host
            }
          ]),expression:"[\n            'smtp_host',\n            {\n              initialValue: webConfig.smtp_host\n            }\n          ]"}],attrs:{"placeholder":"SMTP服务器主机IP"}})],1),_c('a-form-item',{attrs:{"label":"SMTP 端口"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'smtp_port',
            {
              initialValue: _vm.webConfig.smtp_port
            }
          ]),expression:"[\n            'smtp_port',\n            {\n              initialValue: webConfig.smtp_port\n            }\n          ]"}],attrs:{"placeholder":"SMTP服务器端口"}})],1),_c('a-form-item',{attrs:{"label":"SMTP 用户名"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'smtp_username',
            {
              initialValue: _vm.webConfig.smtp_username
            }
          ]),expression:"[\n            'smtp_username',\n            {\n              initialValue: webConfig.smtp_username\n            }\n          ]"}],attrs:{"placeholder":"SMTP服务器用户名"}})],1),_c('a-form-item',{attrs:{"label":"SMTP 密码"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'smtp_password',
            {
              initialValue: _vm.webConfig.smtp_password
            }
          ]),expression:"[\n            'smtp_password',\n            {\n              initialValue: webConfig.smtp_password\n            }\n          ]"}],attrs:{"placeholder":"SMTP服务器密码","type":"password"}})],1),_c('a-form-item',{attrs:{"label":"SMTP SSL Type"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'email_ssl_type',
            {
              initialValue: _vm.webConfig.email_ssl_type
            }
          ]),expression:"[\n            'email_ssl_type',\n            {\n              initialValue: webConfig.email_ssl_type\n            }\n          ]"}]},[_c('a-radio',{attrs:{"value":""}},[_vm._v(" None ")]),_c('a-radio',{attrs:{"value":"ssl"}},[_vm._v(" SSL ")]),_c('a-radio',{attrs:{"value":"tls"}},[_vm._v(" TLS ")])],1)],1),_c('a-form-item',{attrs:{"label":"全局电子邮件签名"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'email_global_sign',
            {
              initialValue: _vm.webConfig.email_global_sign
            }
          ]),expression:"[\n            'email_global_sign',\n            {\n              initialValue: webConfig.email_global_sign\n            }\n          ]"}],attrs:{"rows":"4","placeholder":""}})],1),_c('a-form-item',{attrs:{"label":"全局电子邮件样式"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'email_global_css',
            {
              initialValue: _vm.webConfig.email_global_css
            }
          ]),expression:"[\n            'email_global_css',\n            {\n              initialValue: webConfig.email_global_css\n            }\n          ]"}],attrs:{"rows":"4","placeholder":""}})],1),_c('a-form-item',{attrs:{"label":"电子邮件头部"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'email_global_header',
            {
              initialValue: _vm.webConfig.email_global_header
            }
          ]),expression:"[\n            'email_global_header',\n            {\n              initialValue: webConfig.email_global_header\n            }\n          ]"}],attrs:{"rows":"4","placeholder":""}})],1),_c('a-form-item',{attrs:{"label":"电子邮件尾部"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'email_global_footer',
            {
              initialValue: _vm.webConfig.email_global_footer
            }
          ]),expression:"[\n            'email_global_footer',\n            {\n              initialValue: webConfig.email_global_footer\n            }\n          ]"}],attrs:{"rows":"4","placeholder":""}})],1),_c('a-form-item',{attrs:{"label":"显示邮件来源名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'email_from_name',
            {
              initialValue: _vm.webConfig.email_from_name
            }
          ]),expression:"[\n            'email_from_name',\n            {\n              initialValue: webConfig.email_from_name\n            }\n          ]"}],attrs:{"placeholder":"显示邮件来源名称"}})],1),_c('a-form-item',{attrs:{"label":"显示邮件来源邮箱"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'email_from_email',
            {
              initialValue: _vm.webConfig.email_from_email
            }
          ]),expression:"[\n            'email_from_email',\n            {\n              initialValue: webConfig.email_from_email\n            }\n          ]"}],attrs:{"placeholder":"显示邮件来源邮箱"}})],1),_c('a-form-item',{attrs:{"label":"抄送"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'email_cc',
            {
              initialValue: _vm.webConfig.email_cc
            }
          ]),expression:"[\n            'email_cc',\n            {\n              initialValue: webConfig.email_cc\n            }\n          ]"}],attrs:{"placeholder":"如果要将系统发送的所有电子邮件的副本发送到您的地址，请在此处输入地址。您可以输入多个地址，并用逗号（，）分隔。"}})],1),_c('a-form-item',{attrs:{"label":"密件抄送消息"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'email_bcc',
            {
              initialValue: _vm.webConfig.email_bcc
            }
          ]),expression:"[\n            'email_bcc',\n            {\n              initialValue: webConfig.email_bcc\n            }\n          ]"}],attrs:{"placeholder":"如果要将系统发送的所有电子邮件的副本发送到您的地址，请在此处输入地址。您可以输入多个地址，并用逗号（，）分隔。"}})],1)],1),_c('a-tab-pane',{key:"3"},[_c('span',{attrs:{"slot":"tab"},slot:"tab"},[_c('a-icon',{attrs:{"type":"mobile"}}),_vm._v(" 短信 ")],1),_c('a-form-item',{attrs:{"label":"服务商"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'sms_server_provider',
            {
              initialValue: _vm.webConfig.sms_server_provider
            }
          ]),expression:"[\n            'sms_server_provider',\n            {\n              initialValue: webConfig.sms_server_provider\n            }\n          ]"}]},[_c('a-select-option',{attrs:{"value":"mysubmail"}},[_vm._v(" 赛邮 ")])],1)],1),_c('a-form-item',{attrs:{"label":"Api地址"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'sms_server',
            {
              initialValue: _vm.webConfig.sms_server
            }
          ]),expression:"[\n            'sms_server',\n            {\n              initialValue: webConfig.sms_server\n            }\n          ]"}],attrs:{"placeholder":"短信服务商API地址"}})],1),_c('a-form-item',{attrs:{"label":"AppID"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'sms_appid',
            {
              initialValue: _vm.webConfig.sms_appid
            }
          ]),expression:"[\n            'sms_appid',\n            {\n              initialValue: webConfig.sms_appid\n            }\n          ]"}],attrs:{"placeholder":"短信服务商提供的鉴权appid"}})],1),_c('a-form-item',{attrs:{"label":"AppKey"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'sms_appkey',
            {
              initialValue: _vm.webConfig.sms_appkey
            }
          ]),expression:"[\n            'sms_appkey',\n            {\n              initialValue: webConfig.sms_appkey\n            }\n          ]"}],attrs:{"placeholder":"短信服务商提供的鉴权appKey"}})],1),_c('a-form-item',{attrs:{"label":"SignType"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'sms_sign_type',
            {
              initialValue: _vm.webConfig.sms_sign_type
            }
          ]),expression:"[\n            'sms_sign_type',\n            {\n              initialValue: webConfig.sms_sign_type\n            }\n          ]"}],attrs:{"placeholder":"API授权模式"}})],1),_c('a-form-item',{attrs:{"label":"Signature"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'sms_signature',
            {
              initialValue: _vm.webConfig.sms_signature
            }
          ]),expression:"[\n            'sms_signature',\n            {\n              initialValue: webConfig.sms_signature\n            }\n          ]"}],attrs:{"placeholder":"品牌签名"}})],1)],1),_c('a-tab-pane',{key:"4"},[_c('span',{attrs:{"slot":"tab"},slot:"tab"},[_c('a-icon',{attrs:{"type":"idcard"}}),_vm._v(" 实名认证 ")],1),_c('a-form-item',{attrs:{"label":"支付宝应用ID"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'realname_appid',
            {
              initialValue: _vm.webConfig.realname_appid
            }
          ]),expression:"[\n            'realname_appid',\n            {\n              initialValue: webConfig.realname_appid\n            }\n          ]"}],attrs:{"placeholder":"支付宝应用ID"}})],1),_c('a-form-item',{attrs:{"label":"商户私钥"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'realname_private_key',
            {
              initialValue: _vm.webConfig.realname_private_key
            }
          ]),expression:"[\n            'realname_private_key',\n            {\n              initialValue: webConfig.realname_private_key\n            }\n          ]"}],attrs:{"rows":"4"}})],1),_c('a-form-item',{attrs:{"label":"商户公钥"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'realname_public_key',
            {
              initialValue: _vm.webConfig.realname_public_key
            }
          ]),expression:"[\n            'realname_public_key',\n            {\n              initialValue: webConfig.realname_public_key\n            }\n          ]"}],attrs:{"rows":"4"}})],1),_c('a-form-item',{attrs:{"label":"认证方式"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'realname_type',
            {
              initialValue: _vm.webConfig.realname_type
            }
          ]),expression:"[\n            'realname_type',\n            {\n              initialValue: webConfig.realname_type\n            }\n          ]"}]},[_c('a-select-option',{attrs:{"value":"SMART_FACE"}},[_vm._v(" 多因子快捷认证 ")]),_c('a-select-option',{attrs:{"value":"FACE"}},[_vm._v(" 多因子人脸认证 ")]),_c('a-select-option',{attrs:{"value":"CERT_PHOTO"}},[_vm._v(" 多因子证照认证 ")]),_c('a-select-option',{attrs:{"value":"CERT_PHOTO_FACE"}},[_vm._v(" 多因子证照和人脸认证 ")])],1)],1)],1),_c('a-tab-pane',{key:"5"},[_c('span',{attrs:{"slot":"tab"},slot:"tab"},[_c('a-icon',{attrs:{"type":"user-add"}}),_vm._v(" 推广设置 ")],1),_c('a-form-item',{attrs:{"label":"启用"}},[_c('a-switch',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'promote_enable',
            {
              valuePropName: 'checked',
              initialValue: _vm.promote_enable
            }
          ]),expression:"[\n            'promote_enable',\n            {\n              valuePropName: 'checked',\n              initialValue: promote_enable\n            }\n          ]"}]})],1),_c('a-form-item',{attrs:{"label":"名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'promote_name',
            {
              initialValue: _vm.webConfig.promote_name
            }
          ]),expression:"[\n            'promote_name',\n            {\n              initialValue: webConfig.promote_name\n            }\n          ]"}],attrs:{"placeholder":"推广名称"}})],1),_c('a-form-item',{attrs:{"label":"描述"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'promote_desc',
            {
              initialValue: _vm.webConfig.promote_desc
            }
          ]),expression:"[\n            'promote_desc',\n            {\n              initialValue: webConfig.promote_desc\n            }\n          ]"}],attrs:{"placeholder":"推广描述"}})],1),_c('a-form-item',{attrs:{"label":"使用金额"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'promote_amount',
            {
              initialValue: _vm.webConfig.promote_amount
            }
          ]),expression:"[\n            'promote_amount',\n            {\n              initialValue: webConfig.promote_amount\n            }\n          ]"}],attrs:{"placeholder":""}})],1),_c('a-form-item',{attrs:{"label":"注册佣金"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'promote_register_commission',
            {
              initialValue: _vm.webConfig.promote_register_commission
            }
          ]),expression:"[\n            'promote_register_commission',\n            {\n              initialValue: webConfig.promote_register_commission\n            }\n          ]"}],attrs:{"placeholder":""}})],1),_c('a-form-item',{attrs:{"label":"实名认证佣金"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'promote_realname_commission',
            {
              initialValue: _vm.webConfig.promote_realname_commission
            }
          ]),expression:"[\n            'promote_realname_commission',\n            {\n              initialValue: webConfig.promote_realname_commission\n            }\n          ]"}],attrs:{"placeholder":""}})],1),_c('a-form-item',{attrs:{"label":"教员通过审核佣金"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'promote_apply_teacher_commission',
            {
              initialValue: _vm.webConfig.promote_apply_teacher_commission
            }
          ]),expression:"[\n            'promote_apply_teacher_commission',\n            {\n              initialValue: webConfig.promote_apply_teacher_commission\n            }\n          ]"}],attrs:{"placeholder":""}})],1)],1)],1),_c('a-form-item',{staticStyle:{"text-align":"center"},attrs:{"wrapperCol":{ span: 24 }}},[_c('a-button',{attrs:{"htmlType":"submit","loading":_vm.submitLoading,"type":"primary"}},[_vm._v("提交")]),_c('a-button',{staticStyle:{"margin-left":"8px"},on:{"click":_vm.routerback}},[_vm._v("返回")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }