<template>
  <a-card :body-style="{ padding: '24px 32px' }" :bordered="false">
    <a-form @submit="handleSubmit" :form="form" :labelCol="labelCol" :wrapperCol="wrapperCol">
      <a-tabs default-active-key="1">
        <a-tab-pane key="1">
          <span slot="tab">
            <a-icon type="global" />
            常规
          </span>
          <a-form-item label="公司名">
            <a-input v-decorator="[
              'company_name',
              {
                initialValue: webConfig.company_name
              },
            ]" placeholder="公司名" />
          </a-form-item>
          <a-form-item label="电子邮箱地址">
            <a-input v-decorator="[
              'company_email',
              {
                initialValue: webConfig.company_email
              },
            ]" placeholder="邮箱地址" />
          </a-form-item>
          <a-form-item label="支持https">
            <a-select v-decorator="[
              'support_https',
              {
                initialValue: webConfig.support_https
              }
            ]">
              <a-select-option value="1">
                是
              </a-select-option>
              <a-select-option value="0">
                否
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="域名">
            <a-input v-decorator="[
              'domain',
              {
                initialValue: webConfig.domain
              }
            ]" placeholder="域名" />
          </a-form-item>
          <a-form-item label="后台域名">
            <a-input v-decorator="[
              'admin_domain',
              {
                initialValue: webConfig.admin_domain
              }
            ]" placeholder="后台域名" />
          </a-form-item>
          <a-form-item label="logo地址">
            <a-input v-decorator="[
              'logo_url',
              {
                initialValue: webConfig.logo_url
              }
            ]" placeholder="输入您的徽标URL以显示在电子邮件中，或留空为空白" />
          </a-form-item>
          <a-form-item label="限制活动日志">
            <a-input v-decorator="[
              'active_log_count',
              {
                initialValue: webConfig.active_log_count
              }
            ]" placeholder="您希望保留的最大系统级别活动日志条目数" />
          </a-form-item>
          <a-form-item label="每页显示的记录">
            <a-select v-decorator="[
              'page_count',
              {
                initialValue: webConfig.page_count
              }
            ]">
              <a-select-option value="10">
                10
              </a-select-option>
              <a-select-option value="20">
                20
              </a-select-option>
              <a-select-option value="50">
                50
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="维护模式">
            <a-checkbox v-decorator="[
              'mainte_status',
              {
                valuePropName: 'checked',
                initialValue: webConfig.mainte_status ? true : false
              },
            ]">
              勾选以启用-启用时禁止访问客户区域
            </a-checkbox>
          </a-form-item>
          <a-form-item label="维护模式信息">
            <a-textarea rows="2" placeholder="我们目前正在进行维护，早八点后可访问。" v-decorator="[
              'mainte_desc',
              {
                initialValue: webConfig.mainte_desc
              }
            ]" />
          </a-form-item>
          <a-form-item label="维护模式重定向URL">
            <a-input v-decorator="[
              'mainte_url',
              {
                initialValue: webConfig.mainte_url
              }
            ]" />
          </a-form-item>
        </a-tab-pane>
        <!-- 邮件设置 -->
        <a-tab-pane key="2">
          <span slot="tab">
            <a-icon type="mail" />
            邮件
          </span>
          <a-form-item label="邮件类型">
            <a-select v-decorator="[
              'email_type',
              {
                initialValue: webConfig.email_type
              }
            ]">
              <!-- <a-select-option value="php_mail">
                PHP Mail()
              </a-select-option> -->
              <a-select-option value="smtp">
                SMTP
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="邮件编码">
            <a-select v-decorator="[
              'email_encoding',
              {
                initialValue: webConfig.email_encoding
              }
            ]">
              <a-select-option value="7bit">
                7bit
              </a-select-option>
              <a-select-option value="8bit">
                8bit
              </a-select-option>
              <a-select-option value="base64">
                base64
              </a-select-option>
              <a-select-option value="binary">
                binary
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="SMTP 主机">
            <a-input v-decorator="[
              'smtp_host',
              {
                initialValue: webConfig.smtp_host
              }
            ]" placeholder="SMTP服务器主机IP" />
          </a-form-item>
          <a-form-item label="SMTP 端口">
            <a-input v-decorator="[
              'smtp_port',
              {
                initialValue: webConfig.smtp_port
              }
            ]" placeholder="SMTP服务器端口" />
          </a-form-item>
          <a-form-item label="SMTP 用户名">
            <a-input v-decorator="[
              'smtp_username',
              {
                initialValue: webConfig.smtp_username
              }
            ]" placeholder="SMTP服务器用户名" />
          </a-form-item>
          <a-form-item label="SMTP 密码">
            <a-input v-decorator="[
              'smtp_password',
              {
                initialValue: webConfig.smtp_password
              }
            ]" placeholder="SMTP服务器密码" type="password" />
          </a-form-item>

          <a-form-item label="SMTP SSL Type">
            <a-radio-group v-decorator="[
              'email_ssl_type',
              {
                initialValue: webConfig.email_ssl_type
              }
            ]">
              <a-radio value="">
                None
              </a-radio>
              <a-radio value="ssl">
                SSL
              </a-radio>
              <a-radio value="tls">
                TLS
              </a-radio>
            </a-radio-group>
          </a-form-item>
          <a-form-item label="全局电子邮件签名">
            <a-textarea rows="4" placeholder="" v-decorator="[
              'email_global_sign',
              {
                initialValue: webConfig.email_global_sign
              }
            ]" />
          </a-form-item>
          <a-form-item label="全局电子邮件样式">
            <a-textarea rows="4" placeholder="" v-decorator="[
              'email_global_css',
              {
                initialValue: webConfig.email_global_css
              }
            ]" />
          </a-form-item>
          <a-form-item label="电子邮件头部">
            <a-textarea rows="4" placeholder="" v-decorator="[
              'email_global_header',
              {
                initialValue: webConfig.email_global_header
              }
            ]" />
          </a-form-item>
          <a-form-item label="电子邮件尾部">
            <a-textarea rows="4" placeholder="" v-decorator="[
              'email_global_footer',
              {
                initialValue: webConfig.email_global_footer
              }
            ]" />
          </a-form-item>
          <a-form-item label="显示邮件来源名称">
            <a-input v-decorator="[
              'email_from_name',
              {
                initialValue: webConfig.email_from_name
              }
            ]" placeholder="显示邮件来源名称" />
          </a-form-item>
          <a-form-item label="显示邮件来源邮箱">
            <a-input v-decorator="[
              'email_from_email',
              {
                initialValue: webConfig.email_from_email
              }
            ]" placeholder="显示邮件来源邮箱" />
          </a-form-item>
          <a-form-item label="抄送">
            <a-input v-decorator="[
              'email_cc',
              {
                initialValue: webConfig.email_cc
              }
            ]" placeholder="如果要将系统发送的所有电子邮件的副本发送到您的地址，请在此处输入地址。您可以输入多个地址，并用逗号（，）分隔。" />
          </a-form-item>
          <a-form-item label="密件抄送消息">
            <a-input v-decorator="[
              'email_bcc',
              {
                initialValue: webConfig.email_bcc
              }
            ]" placeholder="如果要将系统发送的所有电子邮件的副本发送到您的地址，请在此处输入地址。您可以输入多个地址，并用逗号（，）分隔。" />
          </a-form-item>
        </a-tab-pane>
        <!-- 邮件设置 -->
        <a-tab-pane key="3">
          <span slot="tab">
            <a-icon type="mobile" />
            短信
          </span>
          <a-form-item label="服务商">
            <a-select v-decorator="[
              'sms_server_provider',
              {
                initialValue: webConfig.sms_server_provider
              }
            ]">
              <a-select-option value="mysubmail">
                赛邮
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="Api地址">
            <a-input v-decorator="[
              'sms_server',
              {
                initialValue: webConfig.sms_server
              }
            ]" placeholder="短信服务商API地址" />
          </a-form-item>
          <a-form-item label="AppID">
            <a-input v-decorator="[
              'sms_appid',
              {
                initialValue: webConfig.sms_appid
              }
            ]" placeholder="短信服务商提供的鉴权appid" />
          </a-form-item>
          <a-form-item label="AppKey">
            <a-input v-decorator="[
              'sms_appkey',
              {
                initialValue: webConfig.sms_appkey
              }
            ]" placeholder="短信服务商提供的鉴权appKey" />
          </a-form-item>
          <a-form-item label="SignType">
            <a-input v-decorator="[
              'sms_sign_type',
              {
                initialValue: webConfig.sms_sign_type
              }
            ]" placeholder="API授权模式" />
          </a-form-item>
          <a-form-item label="Signature">
            <a-input v-decorator="[
              'sms_signature',
              {
                initialValue: webConfig.sms_signature
              }
            ]" placeholder="品牌签名" />
          </a-form-item>
        </a-tab-pane>
        <!-- 邮件设置 -->
        <a-tab-pane key="4">
          <span slot="tab">
            <a-icon type="idcard" />
            实名认证
          </span>
          <a-form-item label="支付宝应用ID">
            <a-input v-decorator="[
              'realname_appid',
              {
                initialValue: webConfig.realname_appid
              }
            ]" placeholder="支付宝应用ID" />
          </a-form-item>
          <a-form-item label="商户私钥">
            <a-textarea rows="4" v-decorator="[
              'realname_private_key',
              {
                initialValue: webConfig.realname_private_key
              }
            ]" />
          </a-form-item>
          <a-form-item label="商户公钥">
            <a-textarea rows="4" v-decorator="[
              'realname_public_key',
              {
                initialValue: webConfig.realname_public_key
              }
            ]" />
          </a-form-item>
          <a-form-item label="认证方式">
            <a-select v-decorator="[
              'realname_type',
              {
                initialValue: webConfig.realname_type
              }
            ]">
              <a-select-option value="SMART_FACE">
                多因子快捷认证
              </a-select-option>
              <a-select-option value="FACE">
                多因子人脸认证
              </a-select-option>
              <a-select-option value="CERT_PHOTO">
                多因子证照认证
              </a-select-option>
              <a-select-option value="CERT_PHOTO_FACE">
                多因子证照和人脸认证
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-tab-pane>
        <!-- 推广设置 -->
        <a-tab-pane key="5">
          <span slot="tab">
            <a-icon type="user-add" />
            推广设置
          </span>
          <a-form-item label="启用">
            <a-switch v-decorator="[
              'promote_enable',
              {
                valuePropName: 'checked',
                initialValue: promote_enable
              }
            ]" />
          </a-form-item>
          <a-form-item label="名称">
            <a-input v-decorator="[
              'promote_name',
              {
                initialValue: webConfig.promote_name
              }
            ]" placeholder="推广名称" />
          </a-form-item>
          <a-form-item label="描述">
            <a-input v-decorator="[
              'promote_desc',
              {
                initialValue: webConfig.promote_desc
              }
            ]" placeholder="推广描述" />
          </a-form-item>
          <a-form-item label="使用金额">
            <a-input-number v-decorator="[
              'promote_amount',
              {
                initialValue: webConfig.promote_amount
              }
            ]" placeholder="" />
          </a-form-item>
          <a-form-item label="注册佣金">
            <a-input-number v-decorator="[
              'promote_register_commission',
              {
                initialValue: webConfig.promote_register_commission
              }
            ]" placeholder="" />
          </a-form-item>
          <a-form-item label="实名认证佣金">
            <a-input-number v-decorator="[
              'promote_realname_commission',
              {
                initialValue: webConfig.promote_realname_commission
              }
            ]" placeholder="" />
          </a-form-item>
          <a-form-item label="教员通过审核佣金">
            <a-input-number v-decorator="[
              'promote_apply_teacher_commission',
              {
                initialValue: webConfig.promote_apply_teacher_commission
              }
            ]" placeholder="" />
          </a-form-item>
        </a-tab-pane>
      </a-tabs>
      <!-- 提交区域 -->
      <a-form-item :wrapperCol="{ span: 24 }" style="text-align: center">
        <a-button htmlType="submit" :loading="submitLoading" type="primary">提交</a-button>
        <a-button style="margin-left: 8px" @click="routerback">返回</a-button>
      </a-form-item>
    </a-form>
  </a-card>
</template>

<script>
// import { nodeAdd } from '@/api/admin'
import { getAllConfig, saveConfig } from '@/api/web_config'

export default {
  name: 'AddRule',
  data() {
    return {
      description: '网站基本设置',
      labelCol: {
        lg: { span: 7 },
        sm: { span: 7 }
      },
      wrapperCol: {
        lg: { span: 10 },
        sm: { span: 17 }
      },

      // form
      form: this.$form.createForm(this),
      submitLoading: false,
      webConfig: {},
      promote_enable: false
    }
  },
  created() {
    this.loadConfig()
  },
  methods: {
    loadConfig() {
      new Promise((resolve, reject) => {
        getAllConfig().then(response => {
          if (response.status === 'success') {
            resolve(response)
          } else {
            reject(response)
          }
        })
      }).then(res => {
        this.webConfig = res.data
        if (parseInt(res.data.promote_enable) === 1) {
          this.promote_enable = true
        } else {
          this.promote_enable = false
        }
      }).catch(error => {
        this.$notification.error({
          message: error.status,
          description: error.message
        })
      })
    },
    // handler
    handleSubmit(e) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log(values)
          this.submitLoading = true
          // 提交数据
          new Promise((resolve, reject) => {
            saveConfig(values).then(response => {
              if (response.status === 'success') {
                resolve(response)
              } else {
                reject(response)
              }
            })
          }).then(res => {
            this.$notification.success({
              message: res.status,
              description: res.message
            })
          }).catch(error => {
            this.$notification.error({
              message: error.status,
              description: error.message
            })
          }).finally(() => {
            this.submitLoading = false
          })
        }
      })
    },
    routerback: function () {
      this.$router.back(-1)
    }
  }
}
</script>
