import { axios } from '@/utils/request'

export function getAllConfig() {
    return axios({
        url: '/admin/config/all',
        method: 'get',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}

export function saveConfig(parameter) {
    return axios({
        url: '/admin/config/save',
        method: 'post',
        data: parameter,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}
